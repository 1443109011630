<template>
  <el-dialog
    title="編輯預約確認設定"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form ref="form" label-position="top" :model="formData" :rules="formRules">
      <el-form-item label="是否開啟預約確認提示：" prop="enable">
        <el-switch
          v-model="formData.enable"
          active-text="開啟"
          inactive-text="關閉"
          @change="enableChange"
        />
      </el-form-item>
      <el-form-item label="標題名稱" prop="title">
        <el-input
          v-model="formData.title"
          placeholder="請輸入"
          :disabled="!formData.enable"
          maxlength="12"
          show-word-limit
        />
      </el-form-item>
      <el-form-item ref="content" label="內容" prop="content">
        <div :class="{'editor-diabled': !formData.enable}">
          <quillEditor
            v-model="formData.content"
            :disabled="!formData.enable"
            :options="editorOption"
          />
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button plain @click="$emit('close')">取消</el-button>
      <el-button
        ref="createBtn"
        type="primary"
        @click="handleUpdate"
      >
        確認
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { noEmptyRules } from '@/validation'
import quillMixin from '@/mixin/quill'
import { UpdateAppointmentCreatePromptPage } from '@/api/reservation'
import { mapGetters } from 'vuex'
import formUtils from '@/utils/form'
import { get } from 'lodash'

export default {
  name: 'EditNoticeSettingsDialog',
  mixins: [quillMixin],
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    imageTemp: null,
    dialog: {
      cropper: false,
    },
    formData: {
      enable: false,
      title: '',
      content: '',
    },
  }),
  computed: {
    ...mapGetters(['shop']),
    formRules () {
      if (!this.formData.enable) {
        return {
          title: [noEmptyRules()],
        }
      }
      return {
        title: [noEmptyRules()],
        content: [noEmptyRules()],
      }
    },
  },
  mounted () {
    this.syncFormData()
  },
  methods: {
    enableChange(e) {
      if(!e) this.$refs.content.clearValidate()
    },
    async handleUpdate () {
      if (!await formUtils.checkForm(this.$refs.form)) return
      if (!await this.updateAppointmentCreatePromptPage()) return
      this.$message.success('更新成功！')
      this.$emit('refresh')
      this.$emit('close')
    },
    syncFormData () {
      this.formData.enable = this.configData.enable
      this.formData.title = get(this.configData, 'title') || null
      this.formData.content = get(this.configData, 'content') || null
    },
    async updateAppointmentCreatePromptPage () {
      const form = this.formData
      const [, err] = await UpdateAppointmentCreatePromptPage({
        shopId: this.shop,
        enable: form.enable,
        title: form.title || undefined,
        content: form.content || '',
      })
      if (err) {
        this.$message.error(err)
        return false
      }
      return true
    },
  },
}
</script>

<style lang="postcss" scoped>
.editor-diabled {
  @apply bg-info-light opacity-50 cursor-not-allowed pointer-events-none;
}
</style>
