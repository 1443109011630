import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: { quillEditor },
  data: () => ({
    editorOption: {
      modules: {
        toolbar: [
          // [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'bullet' }],
          ['link'],
        ],
      },
    },
  }),
}
