<template>
  <div class="linepay-form">
    <el-form-item label="Channel ID" prop="linePayChannelId">
      <!-- <p v-if="showOn('update')">{{ formData.linePayChannelId }}</p> -->
      <el-input
        v-model="syncForm.linePayChannelId"
        placeholder="請輸入"
      />
    </el-form-item>
    <el-form-item label="Channel Secret Key" prop="linePayChannelSecretKey">
      <!-- <p v-if="showOn('update')">{{ formData.linePayChannelSecretKey }}</p> -->
      <el-input
        v-model="syncForm.linePayChannelSecretKey"
        placeholder="請輸入"
      />
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'LinePayForm',
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    syncForm: {
      get () { return this.formData },
      set (v) { return this.$emit('update', v) },
    },
  },
}
</script>

<style lang="postcss" scoped>

</style>
