import { admin2 } from '@/api/instance'

export const GetReservationNotifyConfig = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/appointmentNotificationSetting`,
  })
}

export const GetComposeReservationNotifyConfig = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/appointmentNotificationSetting/singleConfig`,
  })
}

export const CreateReservationNotifyConfig = async ({
  shopId,
  enable,
  triggerTime,
  code,
  hoursAfterCurrentTime,
  lineMessageTitle,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/appointmentNotificationSetting`,
    data: {
      enable,
      triggerTime,
      code,
      hoursAfterCurrentTime,
      lineMessageTitle,
    },
  })
}

export const UpdateReservationNotifyConfig = async ({
  shopId,
  id,
  enable,
  triggerTime,
  code,
  hoursAfterCurrentTime,
  lineMessageTitle,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/appointmentNotificationSetting/${id}`,
    data: {
      enable,
      triggerTime,
      code,
      hoursAfterCurrentTime,
      lineMessageTitle,
    },
  })
}
export const UpdateComposeReservationNotifyConfig = async ({
  shopId,
  oneDayBeforeSetting,
  sameDaySetting,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/appointmentNotificationSetting/singleConfig`,
    data: {
      oneDayBeforeSetting,
      sameDaySetting,
    },
  })
}
