<template>
  <div>
    <el-form-item label="MerchantId" prop="merchantId">
      <el-input
        v-model="syncForm.merchantId"
        placeholder="請輸入 MerchantId"
      />
    </el-form-item>
    <el-form-item label="API Key" prop="apiKey">
      <el-input
        v-model="syncForm.apiKey"
        placeholder="請輸入 API Key"
      />
    </el-form-item>
    <el-form-item label="Digest" prop="digest">
      <el-input
        v-model="syncForm.digest"
        placeholder="請輸入 Digest"
      />
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'JkosPayForm',
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    syncForm: {
      get () { return this.formData },
      set (v) { return this.$emit('update', v) },
    },
  },
}
</script>

<style lang="postcss" scoped>

</style>
