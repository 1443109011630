<template>
  <BaseDialog
    :show="true"
    title="編輯預約提醒通知時間設定"
    btn1="儲存"
    :btn1Loading="loading"
    @confirm="onConfirm"
    @cancel="$emit('close')"
    @close="$emit('close')"
  >
    <div>
      <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
        <el-form-item>
          <template slot="label">
            <FormItemTooltipLabel label="預約前一天提醒" :tooltipWidth="200">
              系統將於設定的時間對「預約開始時間」介於隔日 00:00:00 ~ 23:59:59 之間的訂單發送通知
            </FormItemTooltipLabel>
          </template>
          <el-switch
            v-model="formData.enablePreDay"
            style="margin-right: 20px"
            active-text="開啟"
            inactive-text="關閉"
          />
          <GrayBlockContainer>
            <el-form-item prop="preDayTriggerTime">
              <template slot="label">
                <div class="inline-flex items-center" style="gap: 8px">
                  <p>通知發送時間</p>
                  <span class="label-hint-text">( 系統會在設定的發送時間 1 小時內批次發送通知 )</span>
                </div>
              </template>
              <el-select v-model="formData.preDayTriggerTime" :disabled="!formData.enablePreDay">
                <el-option
                  v-for="time in generateTimes(60)"
                  :key="time"
                  :label="time"
                  :value="time"
                />
              </el-select>
            </el-form-item>
          </GrayBlockContainer>
        </el-form-item>

        <el-form-item>
          <template slot="label">
            <FormItemTooltipLabel label="預約當天提醒" :tooltipWidth="200">
              系統將於設定的時間對「預約開始時間」介於當天{ 通知發送時間 + 時間範圍 } ~ 23:59:59 之間的訂單發送通知
            </FormItemTooltipLabel>
          </template>
          <el-switch
            v-model="formData.enableSameDay"
            style="margin-right: 20px"
            active-text="開啟"
            inactive-text="關閉"
          />
          <GrayBlockContainer class="flex flex-col" style="gap: 12px">
            <el-form-item prop="sameDayTriggerTime">
              <template slot="label">
                <div class="inline-flex items-center" style="gap: 8px">
                  <p>通知發送時間</p>
                  <span class="label-hint-text">( 系統會在設定的發送時間 1 小時內批次發送通知 )</span>
                </div>
              </template>
              <el-select v-model="formData.sameDayTriggerTime" :disabled="!formData.enableSameDay">
                <el-option
                  v-for="time in generateTimes(60)"
                  :key="time"
                  :label="time"
                  :value="time"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="時間範圍" prop="afterHours">
              <div class="flex" style="gap: 7px">
                <span>對距離「通知發送時間」</span>
                <el-input v-model="formData.afterHours" type="number" :disabled="!formData.enableSameDay" style="width: 60px" />
                <span>小時後的預約訂單發送通知</span>
              </div>
            </el-form-item>
          </GrayBlockContainer>
        </el-form-item>
      </el-form>
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent, computed, onMounted } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { generateTimes, timesConvert } from '@/utils/helper'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import { noEmptyRules, rangeRules } from '@/validation'
import { useBaseForm } from '@/use/useForm'
import { useShop } from '@/use/shop'
import { get, toNumber } from 'lodash'
import { UpdateComposeReservationNotifyConfig } from '@/api/reservationNotify'

export default defineComponent({
  name: 'ReservationNotifySetitingsEditModal',
  components: { BaseDialog, FormItemTooltipLabel, GrayBlockContainer },
  props: {
    preDayConfig: { type: Object, default: () => ({}) },
    sameDayConfig: { type: Object, default: () => ({}) },
  },
  emits: ['refresh', 'close'],
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { initFormData, formRef, formData, onSubmit, loading } = useBaseForm()
    initFormData({
      enablePreDay: false,
      enableSameDay: false,
      afterHours: 0,
      sameDayTriggerTime: null,
      preDayTriggerTime: null,
    })

    const formRules = computed(() => {
      const rules = {}
      if (formData.enablePreDay) {
        rules.preDayTriggerTime = [noEmptyRules()]
      }
      if (formData.enableSameDay) {
        rules.sameDayTriggerTime = [noEmptyRules()]
      }

      if (formData.enableSameDay && formData.sameDayTriggerTime) {
        const hour = get(formData.sameDayTriggerTime.split(':'), '[0]') || 0
        const max = 22 - Number(hour)
        rules.afterHours = [noEmptyRules(), rangeRules(0, max)]
      }
      return rules
    })

    const submitData = computed(() => {
      const data = {
        shopId: shopId.value,
        oneDayBeforeSetting: {
          enable: formData.enablePreDay,
          triggerTime: timePeriodToHourNumber(formData.preDayTriggerTime),
        },
        sameDaySetting: {
          enable: formData.enableSameDay,
          triggerTime: timePeriodToHourNumber(formData.sameDayTriggerTime),
          hoursAfterCurrentTime: formData.afterHours,
        },
      }

      return data
    })

    const handleSubmit = async (data, { emit }) => {
      const [res, err] = await UpdateComposeReservationNotifyConfig(data)
      if (err) {
        window.$message.error(err)
      }
      if (!err) {
        window.$message.success('更新成功!')
        emit('refresh')
        emit('close')
      }
    }

    const onConfirm = async () => {
      await onSubmit(handleSubmit, submitData.value, { emit })
    }

    const hourToTimePeriod = (hour) => {
      const mins = Number(hour) * 60
      return timesConvert(mins)
    }

    const timePeriodToHourNumber = (timePeriod) => {
      if (!timePeriod) return 0
      const hourString = timePeriod.split(':')[0]
      if (!hourString) return 0
      return toNumber(hourString)
    }

    const syncFormData = () => {
      if (props.preDayConfig) {
        const triggerTime = get(props.preDayConfig, 'triggerTime')
        const enable = get(props.preDayConfig, 'enable')
        formData.enablePreDay = enable
        formData.preDayTriggerTime = triggerTime != null ? hourToTimePeriod(triggerTime) : null
      }

      if (props.sameDayConfig) {
        const triggerTime = get(props.sameDayConfig, 'triggerTime')
        const enable = get(props.sameDayConfig, 'enable')
        formData.enableSameDay = enable
        formData.sameDayTriggerTime = triggerTime != null ? hourToTimePeriod(triggerTime) : null
        formData.afterHours = get(props.sameDayConfig, 'hoursAfterCurrentTime') || 0
      }
    }

    onMounted(() => {
      syncFormData()
    })

    return { formData, formRef, formRules, generateTimes, onConfirm, loading }
  },
})
</script>

<style lang="postcss" scoped>
.label-hint-text {
  @apply text-sm text-gray-80;
}
</style>
