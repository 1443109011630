<template>
  <el-dialog
    :title="dialogTitle"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form label-position="top">
      <el-form-item label="種類" prop="type">
        <el-select
          v-model="formData.paymentType"
          placeholder="請選擇"
        >
          <el-option
            v-for="type in depositPaymentTypesOptions"
            :key="type.value"
            :label="type.name"
            :value="type.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="是否啟用">
        <el-switch
          v-model="formData[formData.paymentType].enable"
          active-text="是"
          inactive-text="否"
        />
      </el-form-item>
    </el-form>
    <el-form :disabled="disableForm" :rules="formRules" :model="formData" label-position="top">
      <!-- LinePay -->
      <LinePayFrom v-if="formData.paymentType === 'linepay'" :formData.sync="formData.linepay" />
      <!-- 街口 -->
      <JkosPayForm v-if="formData.paymentType === 'jkos'" :formData.sync="formData.jkos" />
      <!-- 藍新 -->
      <NewebPayForm v-if="formData.paymentType === 'newebpay'" :formData.sync="formData.newebpay" />
    </el-form>

    <span slot="footer" class="dialog-footer">
      <div class>
        <el-button plain @click="$emit('close')">取消</el-button>
        <el-button type="primary" @click="handleConfirm">
          {{ btnString }}
        </el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import LinePayFrom from '@/components/Payment/LinePayForm.vue'
import JkosPayForm from '@/components/Payment/JkosPayForm.vue'
import NewebPayForm from '@/components/Payment/NewebPayForm.vue'
import { depositPaymentTypes, paymentParamsMapping } from '@/config/deposit'
import { CreateDepositPayment, UpdateDepositPayment } from '@/api/deposit'
import { mapGetters } from 'vuex'
import { omit, map } from 'lodash'

export default {
  name: 'EditDepositPaymentDialog',
  components: { LinePayFrom, JkosPayForm, NewebPayForm },
  props: {
    selectPayment: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'create',
    },
  },
  data: () => ({
    depositPaymentTypes,
    formData: {
      paymentType: 'wallet',
      jkos: {
        enable: false,
      },
      linepay: {
        enable: false,
      },
      newebpay: {
        enable: false,
      },
      wallet: {
        enable: false,
      },
    },
    formRules: {},
  }),
  computed: {
    ...mapGetters(['shop']),
    depositPaymentTypesOptions () {
      const options = map(depositPaymentTypes, i => ({
        name: i.name,
        value: i.value,
      }))

      return options.filter(i => !i.value.includes('offline') && i.value !== 'free')
    },
    disableForm () {
      // if (this.type === 'edit') {
      //   const formData = this.formData
      //   const paymentType = formData.paymentType
      //   return formData[paymentType].enable
      // }
      if (this.type === 'edit') return true
      return false
    },
    dialogTitle () {
      let title
      if (this.type === 'create') title = '新增付款方式'
      if (this.type === 'edit') title = '編輯付款方式'
      return title
    },

    btnString () {
      let title
      if (this.type === 'create') title = '創建'
      if (this.type === 'edit') title = '編輯'
      return title
    },

    compactData () {
      const paymentType = this.formData.paymentType
      const paymentData = omit(this.formData[paymentType], 'enable')
      const data = {}
      if (paymentType === 'wallet') return data
      for (const key in paymentData) {
        const newKey = paymentParamsMapping[paymentType][key]
        data[newKey] = paymentData[key]
      }

      return data
    },

  },
  mounted () {
    if (this.selectPayment) {
      const payment = this.selectPayment
      this.formData.paymentType = payment.type
      this.formData[payment.type].enable = payment.enable
      this.syncPaymentData()
    }
  },
  methods: {
    async handleConfirm () {
      if (this.type === 'create') await this.createDepositPayment()
      if (this.type === 'edit') await this.updateDepositPayment()
    },

    async createDepositPayment () {
      const paymentType = this.formData.paymentType
      const paymentData = this.formData[paymentType]

      const [res, err] = await CreateDepositPayment({
        shopId: this.shop,
        type: paymentType,
        enable: paymentData.enable,
        config: this.compactData,
      })
      if (err) return this.$message.error(err)
      console.log(res)
      this.$message.success('創建成功！')
      this.$emit('refresh')
      this.$emit('close')
    },
    async updateDepositPayment () {
      const paymentType = this.formData.paymentType
      const paymentData = this.formData[paymentType]
      const [res, err] = await UpdateDepositPayment({
        shopId: this.shop,
        id: this.selectPayment.id,
        enable: paymentData.enable,
      })
      if (err) return this.$message.error(err)
      console.log(res)
      this.$message.success('更新成功！')
      this.$emit('refresh')
      this.$emit('close')
    },
    syncPaymentData () {
      const payment = this.selectPayment
      if (!payment) return
      for (const key in payment.config) {
        const newKey = paymentParamsMapping[payment.type][key]
        this.$set(this.formData[payment.type], newKey, payment.config[key])
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
