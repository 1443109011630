<template>
  <section class="section-block">
    <SectionTitle title="附加服務設定" hideBtn />
    <el-form
      :model="formData"
      label-position="left"
      label-width="250px"
    >
      <el-form-item label="強制選擇附加服務：">
        <el-switch
          v-model="formData.orderServiceAttachClientCheck"
          style="margin-right: 20px"
          active-text="開啟"
          inactive-text="關閉"
          @change="updateConfig"
        />
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { UpdateReservationParameter } from '@/api/reservation'
import { mapGetters } from 'vuex'

export default {
  name: 'AttachServiceSettings',
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    formData: {
      orderServiceAttachClientCheck: false,
    },
  }),
  computed: {
    ...mapGetters(['shop']),
  },
  watch: {
    configData () {
      this.syncFormData()
    },
  },
  mounted () {
    this.syncFormData()
  },
  methods: {
    async updateConfig () {
      const [, err] = await UpdateReservationParameter({
        shopId: this.shop,
        ...this.formData,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.$message.success('更新成功！')
      this.$emit('refresh')
      this.$emit('close')
    },
    syncFormData () {
      this.formData.orderServiceAttachClientCheck = this.configData.orderServiceAttachClientCheck
    },
  },
}
</script>

<style lang="postcss" scoped>

</style>
