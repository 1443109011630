<template>
  <div class="reservation-parameter">
    <PageTitle
      icon="chevron_left"
      title="預約模組參數設定"
      hideBtn
      style="padding-bottom: 24px"
      @iconClick="$router.push({ name: 'Parameters' })"
    />
    <!-- <header class="view-title">
      預約模組參數設定
    </header> -->

    <ReservationClientLink v-if="useLiff" data-permit="'admin.lineLiff.page'" />

    <!-- ANCHOR 時段設定 -->
    <section :v-loading="loading" class="settings-section section-block">
      <SectionTitle cyBtn="edit-time-parameter" title="時段設定" btn="編輯" @edit=";(timeSettingDialog = true), (dialogType = 'time')" />
      <div>
        <el-form label-position="left" label-width="250px">
          <el-form-item label="時段間隔(分鐘) ：" class="settings-item">
            <span data-cy="time-space-view" class="content">{{
              settingData.timeUnit !== null ? settingData.timeUnit : '尚未設定'
            }}</span>
          </el-form-item>

          <el-form-item label="時段偏移(分鐘) ：" class="settings-item">
            <span data-cy="time-offset-view" class="content">{{
              settingData.timeUnitOffset !== null
                ? settingData.timeUnitOffset
                : '尚未設定'
            }}</span>
          </el-form-item>

          <el-form-item label="最小預約時數(分鐘) ：" class="settings-item">
            <span data-cy="min-reservation-time-view" class="content">{{
              settingData.minReservationInterval !== null
                ? settingData.minReservationInterval
                : '尚未設定'
            }}</span>
          </el-form-item>

          <el-form-item label="最大預約時數(分鐘) ：" class="settings-item">
            <span data-cy="max-reservation-time-view" class="content">{{
              settingData.maxReservationInterval
                ? settingData.maxReservationInterval
                : '尚未設定'
            }}</span>
          </el-form-item>

          <el-form-item label="最晚可取消預約時間(小時)：" class="settings-item">
            <span data-cy="cancel-interval-view" class="content">{{
              settingData.cancelInterval
                ? settingData.cancelInterval
                : '尚未設定'
            }}</span>
          </el-form-item>
        </el-form>
      </div>
    </section>

    <!-- ANCHOR 不指定人員設定 -->
    <section :v-loading="loading" class="section-block settings-section">
      <SectionTitle cyBtn="edit-notspecify-parameter" title="不指定人員設定" btn="編輯" @edit=";(noneUnitDialog = true), (dialogType = 'unit')" />
      <div>
        <el-form label-position="left" label-width="250px">
          <el-form-item label="不指定人員狀態 ：" class="settings-item">
            <span data-cy="use-notspecify-view" class="content">{{
              settingData.useNotSpecify ? '開啟' : '關閉'
            }}</span>
          </el-form-item>

          <el-form-item label="跳過選擇服務車次流程 ：" class="settings-item">
            <span data-cy="notspecify-skip-unit-view" class="content">{{
              settingData.notSpecifySkipUnit ? '開啟' : '關閉'
            }}</span>
          </el-form-item>

          <el-form-item label="不指定人員排序 ：" class="settings-item">
            <span class="content">{{ get(noSpecifyUnitOrderOptionConfig, `${settingData.notSpecifyOrderRule}.label`) }}</span>
          </el-form-item>

          <el-form-item label="不指定人員名稱 ：" class="settings-item">
            <span data-cy="notspecify-name-view" class="content">{{
              settingData.notSpecifyName !== null
                ? settingData.notSpecifyName
                : '尚未設定'
            }}</span>
          </el-form-item>

          <el-form-item label="不指定人員照片  ：" class="settings-item">
            <span data-cy="notspecify-img-view" class="content">{{
              settingData.NotSpecifyImage ? '已設置' : '尚未設定'
            }}</span>
          </el-form-item>

          <el-form-item label="不指定人員綁定  ：" class="settings-item">
            <span data-cy="notspecify-unit-view" class="content" style="width: 400px; word-wrap: break-word;">
              {{ notSpecifyUnits }}
            </span>
          </el-form-item>

          <el-form-item label="同時空檔人員分配規則  ：" class="settings-item">
            <span data-cy="notspecify-rule-view" class="content">{{
              settingData.notSpecifyRule
                ? notSpecifyRules[settingData.notSpecifyRule].name
                : '尚未設定'
            }}</span>
          </el-form-item>
        </el-form>
      </div>
    </section>

    <AttachServiceSettings
      :configData="settingData"
      @refresh="findReservationParameter"
    />

    <!-- ANCHOR 進階設定 -->
    <AdvanceSettings
      v-if="useAdvance"
      :configData="formData"
      @refresh="findReservationParameter"
    />

    <!-- ANCHOR 訂金設定 -->
    <DepositSettings v-if="useDeposit" />

    <NoticeSettings />
    <OrderConfirmNoticeSettings />

    <!-- ANCHOR 預約收件地點 -->
    <ReservationAddressSettings
      :configData="formData"
      @refresh="findReservationParameter"
    />

    <ReservationNotifySettings />

    <!-- ANCHOR 時間設定表單 -->
    <el-dialog
      title="編輯預約參數"
      :visible.sync="timeSettingDialog"
      :close-on-click-modal="false"
      @close="resetForm()"
    >
      <el-form
        ref="time"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item label="時段間隔(分鐘)" prop="timeUnit">
          <el-input v-model="formData.timeUnit" data-cy="time-space-input" />
        </el-form-item>

        <el-form-item label="時段偏移(分鐘)" prop="timeUnitOffset">
          <el-input v-model="formData.timeUnitOffset" data-cy="time-offset-input" />
        </el-form-item>

        <el-form-item
          label="最小預約時數(分鐘)"
          prop="minReservationInterval"
        >
          <el-input v-model="formData.minReservationInterval" data-cy="min-reservation-time-input" />
        </el-form-item>

        <el-form-item
          label="最大預約時數(分鐘)"
          prop="maxReservationInterval"
        >
          <el-input v-model="formData.maxReservationInterval" data-cy="max-reservation-time-input" />
        </el-form-item>

        <el-form-item
          label="最晚可取消預約時間(小時)"
          prop="cancelInterval"
        >
          <el-input v-model="formData.cancelInterval" data-cy="cancel-interval-input" />
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button plain @click="timeSettingDialog = false">取消</el-button>
        <el-button
          type="primary"
          data-cy="update-time-parameter"
          @click="updateReservationParameter()"
        >
          儲存
        </el-button>
      </div>
    </el-dialog>

    <!-- ANCHOR 不指定設定表單 -->
    <ImageCropper
      v-if="uploadDialog"
      :image="formData.NotSpecifyImage"
      @close="uploadDialog = false"
      @uploaded="getImage"
    />
    <el-dialog
      title="編輯不指定人員設定"
      :visible.sync="noneUnitDialog"
      :close-on-click-modal="false"
      @close="resetForm()"
    >
      <el-form
        ref="unit"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item
          label="不指定人員狀態 ："
          class="settings-item"
          prop="useNotSpecify"
        >
          <el-switch
            v-model="formData.useNotSpecify"
            data-cy="use-notspecify-switch"
            class="switch-input"
            active-text="開啟"
            inactive-text="關閉"
          />
        </el-form-item>

        <el-form-item
          label="跳過選擇服務車次流程 ："
          class="settings-item"
          prop="notSpecifySkipUnit"
        >
          <el-switch
            v-model="formData.notSpecifySkipUnit"
            data-cy="notspecify-skip-unit-switch"
            :disabled="!formData.useNotSpecify"
            class="switch-input"
            active-text="開啟"
            inactive-text="關閉"
          />
        </el-form-item>

        <el-form-item>
          <template slot="label">
            <FormItemTooltipLabel label="不指定人員排序" :tooltipWidth="200">
              在客人端預約時，「不指定人員」選項顯示於服務車次首位 / 末位
            </FormItemTooltipLabel>
          </template>

          <el-select v-model="formData.notSpecifyOrderRule">
            <el-option
              v-for="option in noSpecifyUnitOrderOptionConfig"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          label="不指定人員名稱 ："
          class="settings-item"
          prop="notSpecifyName"
        >
          <el-input
            v-model="formData.notSpecifyName"
            data-cy="notspecify-name-input"
            :disabled="!formData.useNotSpecify"
            placeholder="請輸入"
          />
        </el-form-item>

        <el-form-item
          label="不指定人員照片  ："
          class="settings-item"
          prop="NotSpecifyImage"
        >
          <UploadButton
            cyUploadBtn="nospecify-unit-img-upload"
            cyUploadedImg="nospecify-unit-img-uploaded"
            :disabled="!formData.useNotSpecify"
            style="width: 150px; height: 150px;"
            :img="formData.NotSpecifyImage"
            @change="imageChange"
          />
          <!-- <img  v-if="image" :src="imgUrl(image)" alt="" > -->
        </el-form-item>

        <el-form-item
          label="不指定人員綁定  ："
          class="settings-item"
          prop="NotSpecifyUnits"
        >
          <ServicesUnitSelect
            data-cy="notspecify-unit-select"
            :disabled="!formData.useNotSpecify"
            :model.sync="formData.NotSpecifyUnits"
            multiple
          />
        </el-form-item>

        <el-form-item
          label="同時空檔人員分配規則  ："
          class="settings-item"
          prop="notSpecifyRule"
        >
          <el-select
            v-model="formData.notSpecifyRule"
            data-cy="notspecify-rule-select"
            :disabled="!formData.useNotSpecify"
            no-data-text="暫無數據"
            placeholder="請選擇規則"
            clearable
          >
            <el-option
              v-for="rule in notSpecifyRules"
              :key="rule.id"
              :label="rule.name"
              :value="rule.id"
            />
          </el-select>
        </el-form-item>

        <p>預約數優先：依照前後14天內預約數，較低者優先，相同則隨機。</p>
        <p>
          不指定預約數優先：依照前後14天內來自不指定的預約數，較低者優先，相同則隨機。
        </p>
        <p>排序優先：依照服務車次的排序，較低者優先。</p>
      </el-form>

      <div slot="footer">
        <el-button plain @click="noneUnitDialog = false">取消</el-button>
        <el-button
          data-cy="edit-notspecify-dialog-confirm-btn"
          type="primary"
          @click="updateReservationParameter()"
        >
          儲存
        </el-button>
      </div>
    </el-dialog>

    <!-- ANCHOR Theme -->
    <section v-if="hasThemePermission" class="section-block">
      <SectionTitle title="樣式與標題設定" btn="編輯" @edit=";(themeDialog = true), (dialogType = 'theme')" />
      <div>
        <el-form label-position="left" label-width="250px">
          <el-form-item label="樣式" class="settings-item">
            <span class="content">
              {{ pageStyleName[settingData.pageStyle] || '-' }}
            </span>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <div class="mb-4"><span>內容</span></div>
        <!-- ANCHOR 預約文字表格 -->
        <el-table
          v-loading="loading"
          :data="pageTextTable"
          empty-text="暫無數據"
        >
          <el-table-column prop="name" label="標題預設名稱" align="center">
            <template slot-scope="scope">
              <div>
                {{ scope.row.defaultValue }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="標題調整名稱" align="center">
            <template slot-scope="scope">
              <div>
                {{ scope.row.value }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作" align="center">
            <template slot-scope="scope">
              <div class="table-edit-btn-group">
                <el-button
                  type="text"
                  class="table-opt-edit"
                  @click="onEditPageText(scope.row.key)"
                >
                  編輯
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </section>
    <!-- ANCHOR 樣式表單 -->
    <el-dialog
      title="樣式與標題設定"
      :visible.sync="themeDialog"
      :close-on-click-modal="false"
      @close="resetForm()"
    >
      <el-form
        ref="theme"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item label="樣式" prop="timeUnit">
          <el-select
            v-model="formData.pageStyle"
            name="pageStyle"
          >
            <el-option
              v-for="styleItem in styleOption"
              :key="styleItem.value"
              :label="styleItem.name"
              :value="styleItem.value"
            />
          </el-select>
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button plain @click="themeDialog = false">取消</el-button>
        <el-button
          type="primary"
          @click="updateReservationParameter()"
        >
          確認
        </el-button>
      </div>
    </el-dialog>
    <!-- ANCHOR 預約文字表單 -->
    <el-dialog
      title="樣式與標題設定"
      :visible.sync="textDialog"
      :close-on-click-modal="false"
      @close="resetForm()"
    >
      <el-form
        ref="text"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item label="標題預設名稱">
          <el-input
            :value="getPageTextDefault"
            :name="'pageTextDefault.'+editTextKey"
            disabled
          />
        </el-form-item>
        <el-form-item label="標題調整名稱" :prop="'pageText.'+editTextKey">
          <el-input
            v-model="formData.pageText[editTextKey]"
            :name="'pageText.'+editTextKey"
            placeholder="請輸入名稱"
          />
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button plain @click="textDialog = false">取消</el-button>
        <el-button
          type="primary"
          @click="updateReservationParameter()"
        >
          確認
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageTitle from '@/components/Title/PageTitle'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import ReservationClientLink from './components/ReservationClientLink.vue'
import AdvanceSettings from './components/AdvanceSettings.vue'
import DepositSettings from './components/DepositSettings.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import ServicesUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
import NoticeSettings from './components/NoticeSettings.vue'
import OrderConfirmNoticeSettings from './components/OrderConfirmNoticeSettings.vue'
import ReservationAddressSettings from './components/ReservationAddressSettings.vue'
import AttachServiceSettings from './components/AttachServiceSettings.vue'
import ReservationNotifySettings from './components/ReservationNotifySettings/ReservationNotifySettings.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'

import {
  UpdateReservationParameter,
  FindReservationParameter,
} from '@/api/reservation'
import {
  pageStyleList,
  pageStyleName,
  pageTextDefault,
  pageTextKeys,
  pageTextDefaultValue,
  noSpecifyUnitOrderOptionConfig,
} from '@/config/reservation'
import { checkUserFeature } from '@/store/modules/permission'
import { mapGetters } from 'vuex'
import { isDigitRules, noEmptyRules, rangeRules, lengthRules } from '@/validation'
import { extractList, imgSrc } from '@/utils/helper'
import formUtils from '@/utils/form'
import { omitBy, get, find } from 'lodash'

export default {
  name: 'ReservationParameter',
  components: {
    PageTitle,
    SectionTitle,
    ImageCropper,
    UploadButton,
    ServicesUnitSelect,
    ReservationClientLink,
    AdvanceSettings,
    DepositSettings,
    NoticeSettings,
    OrderConfirmNoticeSettings,
    ReservationAddressSettings,
    AttachServiceSettings,
    ReservationNotifySettings,
    FormItemTooltipLabel,
  },

  data: () => ({
    loading: false,
    configData: null,
    timeSettingDialog: false,
    noneUnitDialog: false,
    uploadDialog: false,
    themeDialog: false,
    textDialog: false,
    dialogType: '',

    settingData: {},

    formData: {
      timeUnit: '',
      timeUnitOffset: '',
      minReservationInterval: '',
      maxReservationInterval: '',
      cancelInterval: null,
      useNotSpecify: false,
      notSpecifySkipUnit: false,
      notSpecifyName: '',
      NotSpecifyImage: null,
      notSpecifyRule: '',
      NotSpecifyUnits: [],
      pageStyle: '',
      notSpecifyOrderRule: 'first',
      pageText: {
        ...pageTextDefaultValue,
        // APPOINTMENT_CATEGORY: '',
        // APPOINTMENT_SERVICE: '',
        // APPOINTMENT_UNIT: '',
        // APPOINTMENT_SERVICE_ATTACH: '',
      },
    },
    editTextKey: '', // 當前更改的 pageText Key

    formRules: {
      timeUnit: [isDigitRules(), noEmptyRules(), rangeRules(10)],
      timeUnitOffset: [noEmptyRules(), isDigitRules(), rangeRules()],
      minReservationInterval: [
        noEmptyRules(),
        isDigitRules(),
        rangeRules(-1, 525600),
      ],
      maxReservationInterval: [
        noEmptyRules(),
        isDigitRules(),
        rangeRules(-1, 525600),
      ],
      useNotSpecify: [noEmptyRules()],
      notSpecifySkipUnit: [noEmptyRules()],
      notSpecifyName: [noEmptyRules()],
      NotSpecifyImage: [noEmptyRules()],
      notSpecifyRule: [noEmptyRules()],
      NotSpecifyUnits: [noEmptyRules()],
      cancelInterval: [isDigitRules(), rangeRules(0, 9999999)],
      ['pageText.' + pageTextKeys.category]: [lengthRules(0, 8)],
      ['pageText.' + pageTextKeys.service]: [lengthRules(0, 8)],
      ['pageText.' + pageTextKeys.unit]: [lengthRules(0, 8)],
      ['pageText.' + pageTextKeys.serviceAttach]: [lengthRules(0, 8)],
    },

    notSpecifyRules: {
      order: { id: 'order', name: '按排序大小' },
      reservation: { id: 'reservation', name: '預約數分配' },
      notSpecifyReservation: {
        id: 'notSpecifyReservation',
        name: '不指定預約數分配',
      },
      random: { id: 'random', name: '隨機' },
    },
    styleOption: pageStyleList,
    pageStyleName: pageStyleName,
    noSpecifyUnitOrderOptionConfig,
  }),

  computed: {
    ...mapGetters(['shop', 'role', 'userFeatures', 'userPlanFeature']),
    notSpecifyUnits () {
      const list = []
      if (
        !this.settingData.NotSpecifyUnits ||
        !this.settingData.NotSpecifyUnits.length
      ) { return '尚未設定' }
      this.settingData.NotSpecifyUnits.forEach((unit) => {
        list.push(unit.name)
      })
      return list.join('、')
    },
    pageTextTable () {
      const { pageText = {} } = this.settingData
      return pageTextDefault.map(item => {
        return {
          key: item.key,
          defaultValue: item.default,
          value: pageText[item.key] || '-',
        }
      })
    },
    getPageTextDefault () {
      const { editTextKey } = this
      if (!editTextKey) return ''
      return pageTextDefault.find(item => item.key === editTextKey).default
    },
    hasThemePermission () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.appointmentConfig.updatePageSetting')
    },
    useAdvance () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.appointmentConfig.editAdvancedFeatures')
    },
    useDeposit () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'adminView.appointmentDeposit.enable')
    },
    useLiff () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.lineLiff.page')
    },
  },

  watch: {
    formData: {
      handler (data) {
        if (!data.useNotSpecify) this.formData.notSpecifySkipUnit = false
      },
      deep: true,
    },
  },

  async mounted () {
    await this.findReservationParameter()
  },

  methods: {
    get,
    resetForm () {
      const config = JSON.parse(JSON.stringify(this.settingData))
      this.formData = config
      formUtils.resetForm(this.$refs[this.dialogType])
    },

    //= > 更新參數
    async updateReservationParameter () {
      if (!(await formUtils.checkForm(this.$refs[this.dialogType]))) return
      this.loading = true
      const form = { ...this.formData }
      const timeSetting = this.dialogType === 'time'
      const unitSetting = this.dialogType === 'unit'
      const themeSetting = this.dialogType === 'theme'
      const textSetting = this.dialogType === 'text'

      for (const key in form.pageText) {
        if (!form.pageText[key]) form.pageText[key] = get(find(pageTextDefault, { key }), 'default')
      }

      const [, err] = await UpdateReservationParameter({
        shopId: this.shop,
        timeUnit: form.timeUnit,
        timeUnitOffset: timeSetting ? form.timeUnitOffset : undefined,
        minReservationInterval: timeSetting
          ? form.minReservationInterval
          : undefined,
        maxReservationInterval: timeSetting
          ? form.maxReservationInterval
          : undefined,
        cancelInterval: timeSetting ? form.cancelInterval * 60 : undefined,
        useNotSpecify: unitSetting ? form.useNotSpecify : undefined,
        notSpecifySkipUnit: unitSetting ? form.notSpecifySkipUnit : undefined,
        notSpecifyName: unitSetting ? form.notSpecifyName : undefined,
        notSpecifyImage: unitSetting ? form.NotSpecifyImage.id : undefined,
        notSpecifyRule: unitSetting ? form.notSpecifyRule : undefined,
        notSpecifyUnits: unitSetting
          ? extractList('id', form.NotSpecifyUnits)
          : undefined,
        pageStyle: themeSetting ? form.pageStyle : undefined,
        pageText: textSetting ? form.pageText : undefined,
        notSpecifyOrderRule: form.notSpecifyOrderRule,
      })
      this.loading = false
      if (err) {
        console.log(err)
        this.$message.error(err)
        return
      }
      this.timeSettingDialog = false
      this.noneUnitDialog = false
      this.themeDialog = false
      this.textDialog = false

      await this.findReservationParameter()
      this.$message.success('更新成功!')
    },

    imageChange (img) {
      this.formData.NotSpecifyImage = img
      this.uploadDialog = true
    },

    getImage (img) {
      this.formData.NotSpecifyImage = img
      this.uploadDialog = false
    },

    imgUrl (img) {
      return imgSrc(300, img)
    },

    async findReservationParameter () {
      this.loading = true
      try {
        const res = await FindReservationParameter({ shopId: this.shop })
        res.pageText = {
          ...pageTextDefaultValue,
          ...res.pageText,
        }
        this.formData = JSON.parse(JSON.stringify(res))
        this.settingData = JSON.parse(JSON.stringify(res))
        if (this.formData.cancelInterval) this.formData.cancelInterval = (this.formData.cancelInterval / 60).toFixed(1)
        if (this.settingData.cancelInterval) this.settingData.cancelInterval = (this.settingData.cancelInterval / 60).toFixed(1)
        this.loading = false
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },
    onEditPageText (textKey) {
      this.editTextKey = textKey
      this.textDialog = true
      this.dialogType = 'text'
    },
  },
}
</script>

<style scoped lang="scss">
span {
  font-weight: 400;
  color: #5e5e5e;
}

.sub-title {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 500;

  .title-hint {
    @apply mr-[5px] text-primary-100;
  }
}

// .settings-section {
//   margin-bottom: 42.5px;
// }

.settings-item {
  margin-bottom: 16px;
}
</style>
