<template>
  <section class="section-block">
    <SectionTitle title="顧客端預約連結" btn="更新連結" @edit="createLiff('appointment')" />
    <el-form>
      <el-form-item label="顧客端預約">
        <div class="copy-block">
          <a :href="liffUrl">
            {{ liffLink('appointment') ? liffUrl : '尚未設定，請聯繫客服' }}
          </a>
          <div>
            <el-button
              :disabled="!liffUrl"
              class="copy-btn"
              type="primary"
              :class="{ disable: !liffUrl }"
              @click="copyLink(liffUrl)"
            >
              複製
            </el-button>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import SectionTitle from '@/components/Title/SectionTitle'
import { GetLiff, CreateLiff } from '@/api/shop'
import copy from 'clipboard-copy'
import { mapGetters } from 'vuex'

export default {
  name: 'MemberCenterLink',
  components: {
    SectionTitle,
  },
  data: () => ({
    liff: '',
  }),
  computed: {
    ...mapGetters(['shop']),
    liffUrl () {
      if (!this.liffLink('appointment')) return false
      return this.liffLink('appointment').liffUrl
    },
  },

  async mounted () {
    await this.getLiff()
  },
  methods: {
    liffLink (name) {
      if (!this.liff) return false
      return this.liff.find((item) => item.name === name)
    },

    copyLink (link) {
      copy(link)
      this.$message.success('已成功複製連結！')
    },

    async getLiff () {
      try {
        const res = await GetLiff({ shopId: this.shop })
        this.liff = res
      } catch (error) {
        this.liff = []
      }
    },

    async createLiff (name) {
      try {
        await CreateLiff({ shopId: this.shop, name })
        await this.getLiff()
        this.$message.success('更新 Liff 連結成功!')
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
.copy-block {
  @apply flex items-center;
}
.copy-btn {
  @apply h-[30px] rounded-full py-[3px] ml-[30px] px-[30px];
}
</style>
