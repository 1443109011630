<template>
  <section class="reservation-notify-settings section-block">
    <SectionTitle title="預約提醒通知時間設定" @edit="modal.edit = true" />
    <div>
      <el-form label-width="250px" label-position="left">
        <el-form-item label="預約前一天提醒時間：">
          {{ displayData.pre.hintTime }}
        </el-form-item>
        <el-form-item label="預約當日提醒時間：">
          {{ displayData.same.hintTime }}
        </el-form-item>
      </el-form>

      <p class="hint">* 需開啟「基本參數設定 > Line 串接設定 >  預約提醒」才會發送通知</p>
    </div>

    <ReservationNotifySetitingsEditModal
      v-if="modal.edit"
      :preDayConfig="preDayConfig"
      :sameDayConfig="sameDayConfig"
      @close="modal.edit = false"
      @refresh="refresh"
    />
  </section>
</template>

<script>
import { computed, defineComponent, onMounted, ref, reactive } from 'vue'
import ReservationNotifySetitingsEditModal from './components/ReservationNotifySetitingsEditModal.vue'
import { useFetch } from '@/use/fetch'
import { GetComposeReservationNotifyConfig } from '@/api/reservationNotify'
import { useShop } from '@/use/shop'
import { get, find } from 'lodash'
import { plusZero } from '@/utils/helper'

export default defineComponent({
  name: 'ReservationNotifySettings',
  components: { ReservationNotifySetitingsEditModal },
  setup (props) {
    const { simpleFetch } = useFetch()
    const { shopId } = useShop()
    const configData = ref({})
    const modal = reactive({
      edit: false,
    })
    const preDayConfig = computed(() => get(configData.value, 'oneDayBeforeSetting'))
    const sameDayConfig = computed(() => get(configData.value, 'sameDaySetting'))

    const displayData = computed(() => {
      const enablePreDay = get(preDayConfig.value, 'enable')
      const enableSameDay = get(sameDayConfig.value, 'enable')
      const preDayHintHour = get(preDayConfig.value, 'triggerTime')
      const sameDayHintHour = get(sameDayConfig.value, 'triggerTime')

      return {
        pre: {
          hintTime: preDayHintHour != null && enablePreDay ? `${plusZero(preDayHintHour)}:00` : '-',
        },
        same: {
          hintTime: sameDayHintHour != null && enableSameDay ? `${plusZero(sameDayHintHour)}:00` : '-',
        },
      }
    })

    const refresh = async () => {
      simpleFetch(GetComposeReservationNotifyConfig, { shopId: shopId.value },
        (res) => {
          configData.value = res
        })
    }

    onMounted(async () => {
      refresh()
    })
    return {
      get,
      modal,
      refresh,
      configData,
      displayData,
      preDayConfig,
      sameDayConfig,
    }
  },
})
</script>

<style lang="postcss" scoped>
.hint {
    @apply text-danger ;
    @apply font-bold;
}
</style>
