<template>
  <section v-if="showAddress" v-loading="loading" class="section-block">
    <SectionTitle title="預約收件地點" hideBtn />
    <el-form label-position="left" label-width="250px">
      <el-form-item label="是否開啟預約收件地點">
        <el-switch
          v-model="formData.enable"
          active-text="開啟"
          inactive-text="關閉"
          @change="enableChange"
        />
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { UpdateReservationParameter } from '@/api/reservation'
import { checkUserFeature } from '@/store/modules/permission'
import { mapGetters } from 'vuex'

export default {
  name: 'ReservationAddressSettings',
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    loading: false,
    formData: {
      enable: false,
    },
  }),
  computed: {
    ...mapGetters(['shop', 'userPlanFeature', 'userFeatures']),
    showAddress () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'adminView.appointmentConfig.enableUserAddress')
    },
  },
  watch: {
    'configData.enableUserAddress' () {
      this.syncConfigData()
    },
  },
  mounted () {
    this.syncConfigData()
  },
  methods: {
    async enableChange () {
      if (this.loading) return
      this.loading = true
      await this.updateReservationParameter()
      this.loading = false
    },

    async updateReservationParameter () {
      const form = this.formData
      const [, err] = await UpdateReservationParameter({
        shopId: this.shop,
        enableUserAddress: form.enable,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.$message.success('更新成功!')
      this.$emit('refresh')
    },
    syncConfigData () {
      const config = { ...this.configData }
      this.formData.enable = config.enableUserAddress
    },
  },
}
</script>
<style lang="postcss" scoped>

</style>
