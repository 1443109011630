<template>
  <section :v-loading="loading" class="settings-section section-block">
    <SectionTitle title="進階服務設定" hideBtn />
    <div>
      <el-form
        :model="formData"
        label-position="left"
        label-width="250px"
        @submit.prevent.native="updateReservationParameter"
      >
        <el-form-item label="多人預約單服務">
          <el-switch
            v-model="formData.allowOrderMorePeople"
            data-cy="allow-order-multi-people-switch"
            class="switch-input"
            active-text="開啟"
            inactive-text="關閉"
            @change="updateReservationParameter"
          />
        </el-form-item>
        <el-form-item
          v-if="formData.allowOrderMorePeople"
          label="多人預約人數上限"
        >
          <el-input-number
            v-model="formData.maxOrderMorePeopleCount"
            data-cy="multi-people-input"
            placeholder="請輸入人數"
            :min="1"
            :max="10"
            @change="updateReservationParameter"
          />
        </el-form-item>
        <el-form-item label="單人預約多服務">
          <el-switch
            v-model="formData.allowOtherReservation"
            data-cy="allow-order-other-service-switch"
            class="switch-input"
            active-text="開啟"
            inactive-text="關閉"
            @change="updateReservationParameter"
          />
        </el-form-item>

        <template v-if="formData.allowOtherReservation">
          <el-form-item label="其他服務預設開始時間設定">
            <GroupOptionsButton
              :model.sync="formData.secondOrderStartDefault"
              :options="[
                {label: '接續第一個服務', value: 'continue'},
                {label: '與第一個服務同時', value: 'same'},
              ]"
              @change="updateReservationParameter"
            />
          </el-form-item>
          <!-- <el-form-item>
            <template slot="label">
              <div class="flex items-center">
                <span style="margin-right: 2px">是否開放客人自選其他服務時間</span>
                <el-tooltip placement="right">
                  <div slot="content">
                    開放自選時，系統會自動預設時間，而客人可根據需求自行選擇其他時間選項<br>
                    關閉自選時，系統會自動預設時間，而客人不可更改時間選項
                  </div>
                  <span class="material-icons">
                    help_outline
                  </span>
                </el-tooltip>
              </div>
            </template>
            <el-switch
              v-model="formData.secondOrderStartClientChange"
              active-text="開啟"
              inactive-text="關閉"
              @change="updateReservationParameter"
            />
          </el-form-item> -->
        </template>
      </el-form>
    </div>
  </section>
</template>

<script>
import GroupOptionsButton from '@/components/Button/GroupOptionsButton.vue'
import { UpdateReservationParameter } from '@/api/reservation'
import { mapGetters } from 'vuex'

export default {
  name: 'AdvanceSettings',
  components: { GroupOptionsButton },
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    loading: false,
    formData: {
      allowOrderMorePeople: false,
      maxOrderMorePeopleCount: 1,
      allowOtherReservation: false,
      secondOrderStartDefault: '',
      secondOrderStartClientChange: false,
    },
  }),
  computed: {
    ...mapGetters(['shop']),
  },
  watch: {
    configData: {
      handler () {
        this.syncConfigData()
      },
      deep: true,
    },
  },
  mounted () {
    this.syncConfigData()
  },
  methods: {
    async updateReservationParameter () {
      if (this.loading) return
      this.loading = true
      const form = this.formData
      const [, err] = await UpdateReservationParameter({
        shopId: this.shop,
        allowOrderMorePeople: form.allowOrderMorePeople,
        maxOrderMorePeopleCount: form.maxOrderMorePeopleCount || 1,
        allowOtherReservation: form.allowOtherReservation,
        secondOrderStartClientChange: form.secondOrderStartClientChange,
        secondOrderStartDefault: form.secondOrderStartDefault,
      })
      this.loading = false
      if (err) {
        this.$message.error(err)
        return
      }
      this.$message.success('更新成功!')
      this.$emit('refresh')
    },

    syncConfigData () {
      const config = { ...this.configData }
      this.formData.allowOrderMorePeople = config.allowOrderMorePeople
      this.formData.maxOrderMorePeopleCount = config.maxOrderMorePeopleCount || 1
      this.formData.allowOtherReservation = config.allowOtherReservation
      this.formData.secondOrderStartDefault = config.secondOrderStartDefault
      this.formData.secondOrderStartClientChange = config.secondOrderStartClientChange
    },
  },
}
</script>

<style lang="postcss" scoped>
::v-deep .el-input {
  @apply max-w-[560px] w-full;
}
::v-deep .el-input-number {
  @apply max-w-[560px] w-full;
}
</style>
