<template>
  <div class="gray-block-container">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'GrayBlockContainer',
})
</script>

<style lang="postcss" scoped>
.gray-block-container {
  @apply p-[20px] bg-[#F5F7FA] rounded-[8px];
}
</style>
